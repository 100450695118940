$eastern-blue-000: #f1f9fa;
$eastern-blue-100: #377283;
$orange-100: #ff8800;

$body-heading-color: $orange-100;
$link-color: $eastern-blue-100;
$sidebar-color: $eastern-blue-000;
$sidebar-text: $white;
$feedback-color: $orange-100;

$body-font-family: "Meta Pro", system-ui, -apple-system, blinkmacsystemfont, "Segoe UI",
  roboto, "Helvetica Neue", arial, sans-serif, "Segoe UI Emoji";

.post-date {
  font-size: 1.2em;
}
.post-title {
  font-weight: bold;
}
